body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/************ Custom Font ************/
@font-face {
  font-family: Centra;
  src: url(/static/media/CentraNo2-Bold.4ea3794d.ttf);
  font-weight: 700;
}

@font-face {
  font-family: Centra;
  src: url(/static/media/CentraNo2-Medium.63911e85.ttf);
  font-weight: 500;
}

@font-face {
  font-family: Centra;
  src: url(/static/media/CentraNo2-Book.386f0594.ttf);
  font-weight: 400;
}

/************ Default Css ************/
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
  scroll-padding-top: 75px;
}

body {
  font-weight: 400;
  overflow-x: hidden;
  position: relative;
  background-color: #121212 !important;
  color: #fff !important;
  font-family: 'Centra', sans-serif !important;
  --animate-duration: 1.5s;
  transition: height 0.5s ease;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
  line-height: normal;
}

p,
a,
li,
button,
ul {
  margin: 0;
  padding: 0;
  line-height: normal;
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

img {
  width: 100%;
  height: auto;
}

button {
  border: 0;
  background-color: transparent;
}

input:focus,
textarea:focus,
select:focus {
  outline: none;
}

.container {
  margin-top: 5vh;
  ;
  transition: height 0.5s ease;
  min-height: 10vh;
}

@media (min-width:1700px) {
  main .container {
    max-width: 100%;
    padding: 0 150px;
  }

}

@media only screen and (max-width: 767px) {
  .phone-center {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  nav.navbar.scrolled {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
  }
  .navbar{
    display:flex;
    flex-direction:row-reverse;
    float:right;
  }
  .navbar-nav{
    align-items:flex-end;
  }
  span.navbar-text{
    display:flex;
    align-items:center;
    justify-content:flex-end;
  }

  .container-banner {
    display:flex;
    flex-direction:row-reverse;
    margin-top: 5vh;
    min-height: 5vh;
    padding-bottom:5%;
    padding-top: 5%;
    overflow: auto;
  }

  .container {
    margin-top: 5vh;
    min-height: 5vh;
    padding-top: 5%;
    overflow: auto;
  }


  .banner {
    padding-top: 5vh !important;
  }
}

.Typewriter {
  width: 100%;
  color: #003cff;
}

p.success {
  margin-top: 2vh;
  color: rgb(255, 255, 255);
}

p.danger {
  color: red;
}

.project-wrapper {
  overflow: hidden;
  transition: height 5.5s ease;
  min-height: 60vh;
}

.lastUser {
  padding: 3px;
  width: 100%;
  text-align: center;
  border: 2px #38383a solid;
  background-color: #38383a;
}

/************ Navbar Css ************/
nav.navbar {
  position: fixed;
  width: 100%;
  margin-top:-6vh;
  top: 0;
  z-index: 9999;
  transition: 0.32s ease-in-out;
}

nav.navbar.scrolled {

  padding: 0px 0;
  background-color: #252627;
}

nav.navbar a.navbar-brand {
  width: 9%;
}

nav.navbar .navbar-nav .nav-link.navbar-link {
  font-weight: 400;
  color: #030303;
  letter-spacing: 0.8px;
  padding: 0 25px;
  font-size: 18px;
  opacity: 0.75;
}

.scrolled {
  color: white !important;
}

nav.navbar .navbar-nav a.nav-link.navbar-link:hover,
nav.navbar .navbar-nav a.nav-link.navbar-link.active {
  opacity: 1;
}

span.navbar-text {
  display: flex;
  align-items: center;

}

.social-icon {
  display: inline-block;
  margin-left: 14px;
}

.social-icon a {
  width: 42px;
  height: 42px;
  background: rgba(0, 0, 0, 0.945);
  display: inline-flex;
  border-radius: 50%;
  margin-right: 6px;
  align-items: center;
  justify-content: center;
  line-height: 1;
  border: 1px solid rgba(5, 5, 5, 0.5);
}

.social-icon-footer {
  display: inline-block;
  margin-left: 14px;
}

.social-icon-footer a {
  width: 42px;
  height: 42px;
  background: rgba(0, 0, 0, 0.945);
  display: inline-flex;
  border-radius: 50%;
  margin-right: 6px;
  align-items: center;
  justify-content: center;
  line-height: 1;
  border: 1px solid rgb(255, 254, 254);
}

.social-icon-footer a::before {
  content: "";
  width: 42px;
  height: 42px;
  position: absolute;
  background-color: #ffffff;
  border-radius: 50%;
  -webkit-transform: scale(0);
          transform: scale(0);
  transition: 0.3s ease-in-out;
}

.social-icon-footer a:hover::before {
  -webkit-transform: scale(1);
          transform: scale(1);
}

.social-icon-footer a img {
  width: 40%;
  z-index: 1;
  transition: 0.3s ease-in-out;
}

.social-icon-footer a:hover img {
  -webkit-filter: brightness(0) saturate(100%) invert(0%) sepia(7%) saturate(98%) hue-rotate(346deg) brightness(95%) contrast(86%);
          filter: brightness(0) saturate(100%) invert(0%) sepia(7%) saturate(98%) hue-rotate(346deg) brightness(95%) contrast(86%);
}

.social-icon a::before {
  content: "";
  width: 42px;
  height: 42px;
  position: absolute;
  background-color: #ffffff;
  border-radius: 50%;
  -webkit-transform: scale(0);
          transform: scale(0);
  transition: 0.3s ease-in-out;
}

.social-icon a:hover::before {
  -webkit-transform: scale(1);
          transform: scale(1);
}

.social-icon a img {
  width: 40%;
  z-index: 1;
  transition: 0.3s ease-in-out;
}

.social-icon a:hover img {
  -webkit-filter: brightness(0) saturate(100%) invert(0%) sepia(7%) saturate(98%) hue-rotate(346deg) brightness(95%) contrast(86%);
          filter: brightness(0) saturate(100%) invert(0%) sepia(7%) saturate(98%) hue-rotate(346deg) brightness(95%) contrast(86%);
}

.navbar-text button {
  font-weight: 600;
  color: #fff;
  border: 1px solid #fff;
  padding: 18px 34px;
  font-size: 18px;
  margin-left: 18px;
  position: relative;
  background-color: transparent;
  transition: 0.3s ease-in-out;
}

.navbar-text button span {
  z-index: 1;
}

.navbar-text button::before {
  content: "";
  width: 0%;
  height: 100%;
  position: absolute;
  background-color: #fff;
  left: 0;
  top: 0;
  z-index: -1;
  transition: 0.3s ease-in-out;
}

.navbar-text button:hover {
  color: #121212;
}

.navbar-text button:hover::before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
}

nav.navbar .navbar-toggler:active,
nav.navbar .navbar-toggler:focus {
  outline: none;
  box-shadow: none;
}

nav.navbar .navbar-toggler-icon {
  width: 24px;
  height: 17px;
  background-image: none;
  position: relative;
  border-bottom: 2px solid #fff;
  transition: all 300ms linear;
  top: -2px;
}

nav.navbar .navbar-toggler-icon:focus {
  border-bottom: 2px solid #fff;
}

nav.navbar .navbar-toggler-icon:after,
nav.navbar .navbar-toggler-icon:before {
  width: 24px;
  position: absolute;
  height: 2px;
  background-color: #fff;
  top: 0;
  left: 0;
  content: '';
  z-index: 2;
  transition: all 300ms linear;
}

nav.navbar .navbar-toggler-icon:after {
  top: 8px;
}

nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:after {
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  background-color: #fff;
  height: 2px;
}

nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:before {
  -webkit-transform: translateY(8px) rotate(-45deg);
          transform: translateY(8px) rotate(-45deg);
  background-color: #fff;
  height: 2px;
}

nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon {
  border-color: transparent;
}

/************ Banner Css ************/
.banner {
  margin-top: 0;
  padding: 30vh 0 12vh 0;
  min-height: 85vh;
  background-image: url(/static/media/x219hh4w95881.83924589.webp);
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  height: auto;
  transition: height 0.5s ease-out;
}

.banner .tagline {
  font-weight: 700;
  letter-spacing: 0.8px;
  padding: 8px 10px;
  background: linear-gradient(90.21deg, rgba(170, 54, 124, 0.5) -5.91%, rgba(74, 47, 189, 0.5) 111.58%);
  border: 1px solid rgba(255, 255, 255, 0.5);
  font-size: 20px;
  margin-bottom: 16px;
  display: inline-block;
}

.banner h1 {
  font-size: 65px;
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: 1;
  margin-bottom: 20px;
  display: block;
  color: black;
}

.banner p {
  color: #ffffff;
  font-size: 18px;
  letter-spacing: 0.9px;
  -webkit-background-clip: text;
          background-clip: text;
  line-height: 1.5em;
  width: 96%;
  text-shadow: 1px 1px 2.5px rgb(87, 79, 79);

}

.banner button {
  color: #fff;
  font-weight: 700;
  font-size: 20px;
  margin-top: 60px;
  letter-spacing: 0.8px;
  display: flex;
  align-items: center;
}

.banner button svg {
  font-size: 25px;
  margin-left: 10px;
  transition: 0.3s ease-in-out;
  line-height: 1;
}

.banner button:hover svg {
  margin-left: 25px;
}

.banner img {
  width: 25vh;
}

@-webkit-keyframes updown {
  0% {
    -webkit-transform: translateY(-20px);
            transform: translateY(-20px);
  }

  50% {
    -webkit-transform: translateY(20px);
            transform: translateY(20px);
  }

  100% {
    -webkit-transform: translateY(-20px);
            transform: translateY(-20px);
  }
}

@keyframes updown {
  0% {
    -webkit-transform: translateY(-20px);
            transform: translateY(-20px);
  }

  50% {
    -webkit-transform: translateY(20px);
            transform: translateY(20px);
  }

  100% {
    -webkit-transform: translateY(-20px);
            transform: translateY(-20px);
  }
}

.txt-rotate>.wrap {
  color: rgb(0, 68, 255);
  margin-top: 2vh;
  display: block;
}

/************ Skills Css ************/
.skill {
  padding: 0 0 50px 0;
  position: relative;
}

.skill-bx {
  background: #151515;
  border-radius: 64px;
  text-align: center;
  padding: 60px 50px;
  margin-top: 30px;
  border: 2px #347eec solid;
}

.skill h2 {
  font-size: 45px;
  font-weight: 700;
}

.skill p {
  color: #B8B8B8;
  font-size: 18px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  margin: 14px 0 75px 0;
}

.skill-slider {
  width: 80%;
  margin: 0 auto;
  position: relative;
}

.skill-slider .item img {
  width: 50%;
  margin: 0 auto 15px auto;
}

.background-image-left {
  top: 28%;
  position: absolute;
  bottom: 0;
  width: 40%;
  z-index: -4;
}

i {
  font-size: 9vh;
}

/************ Projects Css ************/
.project {
  padding: 80px 0;
  position: relative;
  background-color: black;
  transition: height 0.5s ease;
}

.project h2 {
  font-size: 45px;
  font-weight: 700;
  text-align: center;
}

.project p {
  color: #B8B8B8;
  font-size: 18px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  margin: 14px auto 30px auto;
  text-align: center;
  width: 56%;
}

.project .nav.nav-pills {
  width: 72%;
  margin: 0 auto;
  border-radius: 50px;
  background-color: rgb(255 255 255 / 10%);
  overflow: hidden;
}

.project .nav.nav-pills .nav-item {
  width: 33.33333%;
}

.project .nav.nav-pills .nav-link {
  background-color: transparent;
  border-radius: 0;
  padding: 17px 0;
  color: #fff;
  width: 100%;
  font-size: 17px;
  letter-spacing: 0.8px;
  font-weight: 500;
  position: relative;
  transition: 0.3s ease-in-out;
  text-align: center;
  z-index: 0;
}

.project .nav.nav-pills .nav-link::before {
  content: "";
  position: absolute;
  width: 0;
  height: 100%;
  background: linear-gradient(90.21deg, #AA367C -5.91%, #4A2FBD 111.58%);
  top: 0;
  left: 0;
  z-index: -1;
  transition: 0.3s ease-in-out;
}

.project .nav.nav-pills .nav-link.active::before {
  width: 100% !important;
}

.project .nav.nav-pills .nav-link.active {
  border: 1px solid rgba(255, 255, 255, 1);
}

.nav-link#projects-tabs-tab-first {
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 55px 0px 0px 55px;
}

.nav-link#projects-tabs-tab-second {
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}

.nav-link#projects-tabs-tab-third {
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 0 55px 55px 0;
}

.proj-imgbx {
  position: relative;
  border-radius: 30px;
  overflow: hidden;
  margin-bottom: 24px;
  border: 3px solid #347eec;
  cursor:pointer;
}

.proj-imgbx img{
  min-height:25vh;

}
h6{
  margin-top:2vh !important;
}
.proj-imgbx::before {
  content: "";
  background: linear-gradient(90.21deg, #160ca3 -5.91%, #0657c2 111.58%);
  opacity: 0.85;
  position: absolute;
  width: 100%;
  height: 0;
  transition: 0.4s ease-in-out;
}

.proj-imgbx:hover::before {
  height: 100%;
}

.proj-txtx {
  position: absolute;
  text-align: center;
  top: 65%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  transition: 0.5s ease-in-out;
  opacity: 0;
  width: 100%;
}

.proj-imgbx:hover .proj-txtx {
  top: 50%;
  opacity: 1;
}

.proj-txtx h4 {
  font-size: 30px;
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: 1.1em;
}

.proj-txtx span {
  font-style: italic;
  font-weight: 400;
  font-size: 15px;
  letter-spacing: 0.8px;
}

.background-image-right {
  top: 20%;
  position: absolute;
  bottom: 0;
  width: 35%;
  right: 0;
  z-index: -4;
}

/************ Projects Css ************/
.contact {
  background: linear-gradient(90.21deg, #1634b8 -5.91%, #025be0 111.58%);
  padding: 60px 0 200px 0;
}

.contact img {
  width: 92%;
  border: 10px solid white;
  border-radius: 50%;
  -webkit-animation: floating 3s ease-in-out infinite;
          animation: floating 3s ease-in-out infinite;
}

@-webkit-keyframes floating {
  0% {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
  }

  50% {
    -webkit-transform: translate(0, -10px);
            transform: translate(0, -10px);
  }

  100% {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
  }
}

@keyframes floating {
  0% {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
  }

  50% {
    -webkit-transform: translate(0, -10px);
            transform: translate(0, -10px);
  }

  100% {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
  }
}

.contact h2 {
  font-size: 45px;
  font-weight: 700;
  margin-bottom: 30px;
}

.contact form input,
.contact form textarea {
  width: 100%;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 20px;
  color: #fff;
  margin: 0 0 8px 0;
  padding: 18px 26px;
  font-weight: 500;
  font-size: 18px;
  letter-spacing: 0.8px;
  transition: 0.3s ease-in-out;
}

.contact form input:focus,
.contact form textarea:focus {
  background: rgba(255, 255, 255, 1);
  color: #121212;
}

.contact form input::-webkit-input-placeholder, .contact form textarea::-webkit-input-placeholder {
  font-size: 16px;
  font-weight: 400;
  color: #fff;
}

.contact form input::placeholder,
.contact form textarea::placeholder {
  font-size: 16px;
  font-weight: 400;
  color: #fff;
}

.contact form input:focus::-webkit-input-placeholder, .contact form textarea:focus::-webkit-input-placeholder {
  color: #121212;
  opacity: 0.8;
}

.contact form input:focus::placeholder,
.contact form textarea:focus::placeholder {
  color: #121212;
  opacity: 0.8;
}

.contact form button {
  font-weight: 700;
  color: #000;
  background-color: #fff;
  padding: 14px 48px;
  font-size: 18px;
  margin-top: 25px;
  border-radius: 0;
  position: relative;
  transition: 1.3s ease-in-out;
}
.contact-form-button.disabled {
  background-color: #636464;
  cursor: not-allowed;
  color: #fff;
}

.contact form button span {
  z-index: 1;
  position: relative;
}

.contact form button:hover {
  color: #fff;
}


/************ Footer Css ************/
.footer {
  padding: 0 0 50px 0;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}

.newsletter-bx {
  background: #FFFFFF;
  border-radius: 55px;
  color: #121212;
  padding: 85px 125px;
  margin-bottom: 80px;
  margin-top: -122px;
}

.newsletter-bx h3 {
  font-weight: 700;
  letter-spacing: 0.5px;
  line-height: 1.2em;
}

.new-email-bx {
  background: #fff;
  padding: 5px;
  border-radius: 20px;
  position: relative;
  z-index: 0;
  display: flex;
  align-items: center;
}

.new-email-bx::before {
  content: "";
  background: linear-gradient(90.21deg, #AA367C -5.91%, #4A2FBD 111.58%);
  border-radius: 20px;
  position: absolute;
  z-index: -1;
  top: -1px;
  left: -1px;
  bottom: -1px;
  right: -1px;
}

.new-email-bx::after {
  content: "";
  background: #fff;
  border-radius: 20px;
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.new-email-bx input {
  width: 100%;
  color: #121212;
  font-weight: 500;
  background: transparent;
  border: 0;
  padding: 0 15px;
}

.new-email-bx button {
  background: linear-gradient(90.21deg, #AA367C -5.91%, #4A2FBD 111.58%);
  padding: 20px 65px;
  color: #fff;
  font-weight: 500;
  letter-spacing: 0.5px;
  border-radius: 18px;
}

.footer img {
  width: 26%;
}

.footer p {
  font-weight: 400;
  font-size: 14px;
  color: #B8B8B8;
  letter-spacing: 0.5px;
  margin-top: 20px;
}

.animate__backInLeft {
  --animate-duration: 3s;
}
.disabled{
  background-color: lightgray;
  cursor: not-allowed;
}
